class ZIDXAccountSavedSearchEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSavedSearchEditContainer";
    }
    getMatchURL(){
        return "/account/saved-searches/edit";
    }
    render(){
        // console.log("saved search edit");
    }
}